import { Box, Button, MenuItem, Modal, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { API_URL, getToken } from '../../Helpers/constants';
import styles from './CreateTask.module.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function CreateTask() {
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // Track delete confirmation
  const [editMode, setEditMode] = useState(false); // Track if it's edit mode
  const [selectedTaskId, setSelectedTaskId] = useState(null); // Store the task ID for editing
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState(new Date());
  const [jobProfileRounds, setJobProfileRounds] = useState([]); // To store the list of job profile rounds
  const [selectedJobProfileRound, setSelectedJobProfileRound] = useState(''); // To store the selected job profile round
  const token = getToken();

  useEffect(() => {
    fetchTasks();
    fetchJobProfileRounds(); // Fetch the job profile rounds when the component loads
    // eslint-disable-next-line
  }, []);

  const fetchTasks = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/tasks/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          setTasks(result);
        } else {
          console.error("Unexpected data structure:", result);
          setTasks([]); // Set tasks to an empty array if result is not an array
        }
      })
      .catch((error) => console.error('Error fetching tasks:', error));
  };

  const fetchJobProfileRounds = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/job-profile-rounds/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          setJobProfileRounds(result); // Store the job profile rounds in state
        } else {
          console.error("Unexpected data structure:", result);
          setJobProfileRounds([]);
        }
      })
      .catch((error) => console.error('Error fetching job profile rounds:', error));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false); // Reset edit mode on close
    setSelectedTaskId(null); // Clear selected task
  };

  const handleAddTask = () => {
    setName('');
    setDescription('');
    setDeadline(new Date());
    setSelectedJobProfileRound('');
    setEditMode(false); // Ensure it's add mode
    handleOpen();
  };

  const handleEditTask = (task) => {
    setName(task.name);
    setDescription(task.description);
    setDeadline(new Date(task.deadline));
    setSelectedJobProfileRound(task.job_profile_round);
    setSelectedTaskId(task.id);
    setEditMode(true); // Set edit mode
    handleOpen();
  };

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      job_profile_round: selectedJobProfileRound, // Use the selected job profile round
      name: name,
      description: description,
      deadline: deadline.toISOString(),
    });

    const requestOptions = {
      method: editMode ? 'PATCH' : 'POST', // Use PATCH if editing, POST if adding new task
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const url = editMode
      ? `${API_URL}/tasks/${selectedTaskId}/` // Use PATCH URL if editing
      : `${API_URL}/tasks/`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        handleClose();
        fetchTasks(); // Refresh tasks
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteTask = (taskId) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API_URL}/tasks/${taskId}/`, requestOptions)
      .then(() => {
        setDeleteDialogOpen(false);
        fetchTasks(); // Refresh tasks after deletion
      })
      .catch((error) => console.error('Error deleting task:', error));
  };

  const openDeleteDialog = (taskId) => {
    setSelectedTaskId(taskId); // Store task ID to delete
    setDeleteDialogOpen(true); // Open confirmation dialog
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false); // Close confirmation dialog
    setSelectedTaskId(null); // Clear selected task ID
  };

  return (
    <div className={styles.container}>
      {/* Button to open the modal for adding new task */}
      <div className={styles.divAddNewTask}>
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          Add New Task
        </Button>
      </div>

      <h1 className={styles.taskList}>Tasks List</h1>
      <div className={styles.meetingListContainer}>
        <div className={styles.meetingCards}>
          {tasks.length === 0 ? (
            <p>No tasks available.</p>
          ) : (
            tasks.map((task) => (
              <div key={task.id} className={styles.meetingCard}>
                <h3 className={styles.meetingName}>Task Name: {task.name}</h3>
                <p className={styles.meetingInstructor}><strong>Description:</strong> {task.description}</p>
                <p className={styles.meetingDate}><strong>Job Profile Round:</strong> {task.job_profile_round}</p>
                <p className={styles.meetingDate}><strong>Deadline:</strong> {new Date(task.deadline).toLocaleDateString()}</p>

                {/* Edit Button */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleEditTask(task)}
                  style={{ marginTop: '10px', marginRight: '10px' }}
                >
                  Edit <EditIcon />
                </Button>

                {/* Delete Button */}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => openDeleteDialog(task.id)}
                  style={{ marginTop: '10px' }}
                >
                  Delete <DeleteIcon />
                </Button>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Modal for adding/editing a task */}
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBox}>
          <h2>{editMode ? 'Edit Task' : 'Add a New Task'}</h2>

          {/* Dropdown for selecting job profile round */}
          <Select
            fullWidth
            value={selectedJobProfileRound}
            onChange={(e) => setSelectedJobProfileRound(e.target.value)}
            displayEmpty
            margin="normal"
          >
            <MenuItem value="" disabled>
              Select Job Profile Round
            </MenuItem>
            {jobProfileRounds.map((round) => (
              <MenuItem key={round.code} value={round.code}>
                {round.code}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            label="Task Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Deadline"
              value={deadline}
              onChange={(newValue) => setDeadline(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {editMode ? 'Save Changes' : 'Submit Task'}
          </Button>
        </Box>
      </Modal>

      {/* Confirmation dialog for delete */}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this task? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteTask(selectedTaskId)} color="error">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateTask;
